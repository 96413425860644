import $ from "jquery";

$(function () {
  "use strict";
  var url = window.location + "";
  var path = url.replace(
    window.location.protocol + "//" + window.location.host + "/",
    ""
  );
  
  // Find the element matching the URL
  var element = $("ul#sidebarnav a").filter(function () {
    return this.href === url || this.href === path;
  });
  
  // Add active class to parent menu items of the clicked element
  element.parentsUntil(".sidebar-nav").each(function () {
    if ($(this).is("li") && $(this).children("a").length !== 0) {
      $(this).children("a").addClass("active");
    }
  });
  
  // Toggle active and in classes on click
  $("#sidebarnav a").on("click", function (e) {
    if ($(this).hasClass("has-arrow")) {
      e.preventDefault();
      var subMenu = $(this).next("ul");
      
      // Toggle the 'in' class on sub-menu
      subMenu.toggleClass("in");
      
      // Toggle the 'active' class on the clicked element
      $(this).toggleClass("active");
    }
    else {
      // Remove active class from all other menu items
      $("#sidebarnav a").removeClass("active");
      // Add active class to the clicked element
      $(this).addClass("active");
    }
  });
  
  // Click event for nested submenu items
  $("#sidebarnav ul ul li a").on("click", function (e) {
    e.stopPropagation(); // Prevent the event from bubbling up
    $("#sidebarnav ul ul li a").removeClass("active"); // Remove active class from all submenu items
    $(this).addClass("active"); // Add active class to the clicked submenu item
  });
});

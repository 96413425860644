import React, { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { loginHistory } from "../../store/host/action";
import { TablePagination } from "@material-ui/core";
import TablePaginationActions from "./TablePagination";
import { Link } from "react-router-dom";

const LoginHistory = (props) => {
  const { loginHistory } = useSelector((state) => state.host);

  const getId = localStorage.getItem("hostId");
  const logOut = localStorage.getItem("logOut");

  console.log("logOut", logOut);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    props.loginHistory(getId, "host");
  }, [getId]);

  useEffect(() => {
    setData(loginHistory);
  }, [loginHistory]);
  return (
    <>
      <div class="page-breadcrumb">
        <div class="row">
          <div class="col-7 align-self-center">
            <div class="d-flex align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0 p-0">
                  <li class="breadcrumb-item">
                    <Link to="/admin/dashboard" class="text-muted">
                      Home
                    </Link>
                  </li>
                  <li class="breadcrumb-item">
                    <Link to="/admin/host/agency" class="text-muted">
                      Agency
                    </Link>
                  </li>
                  <li class="breadcrumb-item ">
                    <Link to="/admin/host" class="text-muted">
                      Host
                    </Link>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Login History
                  </li>
                </ol>
              </nav>
            </div>
          </div>
         
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table
                    id="zero_config"
                    class="table table-striped table-bordered no-wrap"
                  >
                    <thead>
                      <tr>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 ? (
                        <Fragment>
                          {(rowsPerPage > 0
                            ? data.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : data
                          ).map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.startTime ? data.startTime : "-"}</td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.endTime ? data.endTime : "-"}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.duration ? data.duration : "-"}
                                </td>
                              </tr>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <tr>
                          <td colSpan="3" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>duration</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="py-2">
                  <TablePagination
                    id="pagination"
                    component="div"
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { loginHistory })(LoginHistory);

import { TablePagination } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import TablePaginationActions from "./TablePagination";
import { callHistory } from "../../store/host/action";
import { connect, useSelector } from "react-redux";
//datepicker
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import dayjs from "dayjs";

const CallHistory = (props) => {
  const { callHistory } = useSelector((state) => state.host);

  const location = useLocation();

  const getId = localStorage.getItem("hostId");

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [date, setDate] = useState("");

  var date_ = new Date();
  const [start, setStart] = useState(
    dayjs(new Date(date_.getFullYear(), date_.getMonth(), 1)).format(
      "YYYY-MM-DD"
    )
  );
  const [end, setEnd] = useState(dayjs(date_).format("YYYY-MM-DD"));

  const [startDate, setStartDate] = useState(
    dayjs(new Date(date_.getFullYear(), date_.getMonth(), 1)).format(
      "YYYY-MM-DD"
    )
  );
  const [endDate, setEndDate] = useState(dayjs(date_).format("YYYY-MM-DD"));
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    props.callHistory(getId, "host");
  }, [getId, "host"]);

  useEffect(() => {
    setData(callHistory);
  }, [callHistory]);

  const handleCallback = (event, picker) => {
    
    picker.element.val(
      picker.startDate.format("YYYY-MM-DD") +
        " / " +
        picker.endDate.format("YYYY-MM-DD")
    );
    const start_ = dayjs(picker.startDate).format("YYYY-MM-DD");
    const end_ = dayjs(picker.endDate).format("YYYY-MM-DD");
    const data_ = { startDate: start_, endDate: end_ };
    setStart(start_);
    setEnd(end_);
    setDate(start_ + " to " + end_);
    props.callHistory(getId, "host", data_);
  };

  return (
    <>
      <div class="page-breadcrumb">
        <div class="row">
          <div class="col-7 align-self-center">
            <div class="d-flex align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0 p-0">
                  <li class="breadcrumb-item">
                    <Link to="/admin/dashboard" class="text-muted">
                      Home
                    </Link>
                  </li>
                  <li class="breadcrumb-item">
                    <Link to="/admin/host/agency" class="text-muted">
                      Agency
                    </Link>
                  </li>
                  <li class="breadcrumb-item ">
                    <Link to="/admin/host" class="text-muted">
                      Host
                    </Link>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Call History
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-5">
            <DateRangePicker
              initialSettings={{
                applyButtonClasses: "btn-default",
                autoUpdateInput: false,
                locale: {
                  cancelLabel: "Clear",
                },

                // startDate: startDate,
                // endDate: endDate,
                maxDate: new Date(),
              }}
              onApply={handleCallback}
            >
              <input
                type="text"
                class="form-control float-right"
                placeholder="Select Date"
                value={!date ? "Select Date" : date}
                style={{ width: 180 }}
              />
            </DateRangePicker>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table
                    id="zero_config"
                    class="table table-striped table-bordered no-wrap"
                  >
                    <thead>
                      <tr>
                        <th>Call Count</th>
                        {/* <th>UserName</th> */}
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 ? (
                        <Fragment>
                          {(rowsPerPage > 0
                            ? data.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : data
                          ).map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.total}</td>
                                {/* <td style={{ verticalAlign: "middle" }}>
                                  {data.username}
                                </td> */}
                                <td style={{ verticalAlign: "middle" }}>
                                  {dayjs(data._id).format("YYYY-MM-DD")}
                                </td>
                              </tr>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <tr>
                          <td colSpan="2" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Call Count </th>
                        {/* <th>UserName</th> */}
                        <th>Date</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="py-2">
                  <TablePagination
                    id="pagination"
                    component="div"
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { callHistory })(CallHistory);

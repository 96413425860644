import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const AuthRouter = ({ path, component: Component, ...rest }) => {
  const isAuth = useSelector((state) => state.admin.isAuth);

  return <Route
  {...rest}
  render={(props) =>
    isAuth === true ? <Redirect to="/admin" /> : <Component {...props} />
  }
/>
};

export default AuthRouter;

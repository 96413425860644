import axios from "axios";

import {
  CLEAR_LOGIN_ERROR,
  SET_ADMIN,
  SET_LOGIN_ERROR,
  UPDATE_PROFILE,
  SIGNUP_ADMIN,
  CLEAR_REGISTER_ERROR,
  SET_REGISTER_ERROR,
  SET_UPDATE_CODE_ERROR,
  CLEAR_UPDATE_CODE_ERROR,
  GET_PROFILE
} from "./types";

export const login = (email, password, key, package_) => (dispatch) => {
  dispatch({ type: CLEAR_LOGIN_ERROR });
  axios
    .post("/admin/login", { email, password, key, package_ })
    .then((res) => {
      if(res.data.status){
        window.location.reload();
        dispatch({ type: SET_ADMIN, payload: res.data.token });
      }else{
        dispatch({ type: SET_LOGIN_ERROR, payload: res.data.message });
      }
    })
    .catch((error) => {
      dispatch({ type: SET_LOGIN_ERROR, payload: error });
    });
};

export const updateProfile = (profileData) => (dispatch) => {
  axios
    .patch("/admin", profileData)
    .then((res) => {
      dispatch({ type: UPDATE_PROFILE, payload: res.data.data });
    })
    .catch(({ response }) => {
      console.log(response?.data);
    });
};

export const getProfile = () => (dispatch) => {
  axios
    .get("/admin")
    .then((res) => {
      dispatch({ type: GET_PROFILE, payload: res.data.data });
    })
    .catch((error) => console.log(error));
};

